import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import {
  setTitle,
  setDescription,
  setRobots,
  setTwitterConfig,
  // setViewport
} from '@/mixins/index.js'

import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Popup from '@/views/Popup.vue'
import Overlay from '@/views/Overlay.vue'
import YahooCv from '@/views/YahooCv.vue'
import ContactForm from '@/views/Contact/ContactForm.vue'
import ContactFormCheck from '@/views/Contact/ContactFormCheck.vue'
import ContactFormComplete from '@/views/Contact/ContactFormComplete.vue'
import Tokuteisho from '@/views/Tokuteisho.vue'
import GoogleCv from '@/views/GoogleCv.vue'
import MicrosoftCv from '@/views/MicrosoftCv.vue'
import YdaCv from '@/views/YdaCv.vue'
import TiktokCv from '@/views/TiktokCv.vue'
import Kiyaku from '@/views/Kiyaku.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import Complete from '@/views/Complete.vue'
import RegisterError from '@/views/RegisterError.vue'
import OnetimeOfferComplete from '@/views/OnetimeOfferComplete.vue'

// 
import LpTag from '@/views/LpTag.vue'


function convertKebabToCamel(p) {
  return p.replace(/-./g,
    function (s) {
      return s.charAt(1).toUpperCase();
    }
  );
}
Vue.use(VueRouter)

const routes = [{
  path: '/',
  // redirect: '/google-cv', //リダイレクト
  name: 'home',
  component: Home,
  meta: {
    title: '広告成果連携ツール「アドタスカル」',
    description: 'アドアフィリエイター必見！広告成果連携ツール「アドタスカル」は、Google広告/Facebook広告/Microsoft広告の広告媒体管理画面上に計測される成果データと、ASP管理画面上に計測される成果データの乖離を自動/半自動で防止できる便利ツールです。Facebook広告コンバージョンAPI連携ツールも提供中！初回おためし100円実施中！'
  }
},
{
  path: '/login',
  name: 'login',
  component: Login,
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/popup',
  name: 'popup',
  component: Popup,
  meta: {
    title: '離脱防止・特定位置登場ポップアアップなら「アドタスカル」',
    description: '離脱防止のためのポップアップや、特定位置に到達した閲覧者に表示するポップアアップなら「アドタスカル」をご利用ください。閲覧者の到達位置によって、表示するポップアップを変えることができ、また、タグの簡単設置、料金最安値水準、A/Bテスト機能ありと、効果改善に最適なツールとなっています。'
  }
},
{
  path: '/overlay',
  name: 'overlay',
  component: Overlay,
  meta: {
    title: '簡単設置できるオーバーレイなら「アドタスカル」',
    description: '広告成果連携ツール「アドタスカル」では、サイトに導入するだけでパフォーマンス改善が期待できるオーバーレイを、簡単設置＆お値打ち価格で提供しています。リンク先を指定できるボタンタイプと、テキストを表示するテキストタイプを用意しています。'
  }
},
{
  path: '/google-cv',
  name: 'google-cv',
  component: GoogleCv,
  props: {},
  meta: {
    title: 'ASP管理画面とGoogle広告のCV数の乖離防止｜広告成果連携ツール「アドタスカル」',
    description: 'Google広告成果連携ツール「アドタスカル」は、ASP管理画面に計上される成果とGoogle広告(検索・ディスプレイ)管理画面上の成果の乖離をなくすツールです。オフラインコンバージョン機能を用いて、本来は手動で行う作業をアドタスカルで自動化・半自動化でき、その手間を効率化。CVが正確に計上されるので広告の自動最適化がかかる！ASPへのタグの設置の依頼も不要！'
  }
},
{
  // path: '/yahoo-cv/:password',
  path: '/yahoo-cv',
  name: 'yahoo-cv',
  component: YahooCv,
  // beforeEnter(to, from, next) {
  //   if (to.params.password === 'Ap6sAQrxLr3ebJ5e') {
  //     next();
  //   } else {
  //     next('/');
  //   }
  // },
  props: {},
  meta: {
    title: 'ASP管理画面とYahoo!検索広告のCV数の乖離防止｜広告成果連携ツール「アドタスカル」',
    description: 'Yahoo!検索広告成果連携ツール「アドタスカル」は、ASP管理画面に計上される成果とYahoo!検索広告(検索)管理画面上の成果の乖離をなくすツールです。オフラインコンバージョン機能を用いて、本来は手動で行う作業をアドタスカルで自動化・半自動化でき、その手間を効率化。CVが正確に計上されるので広告の自動最適化がかかる！ASPへのタグの設置の依頼も不要！'
  }
},
{
  path: '/microsoft-cv',
  name: 'microsoft-cv',
  component: MicrosoftCv,
  props: {},
  meta: {
    title: 'ASP管理画面とMicrosoft広告のCVの乖離防止｜広告成果連携ツール「アドタスカル」',
    description: 'Microsoft広告成果連携ツール「アドタスカル」は、ASP管理画面に計上される成果とMicrosoft広告管理画面上の成果の乖離をなくすツールです。オフラインコンバージョン機能を用いて、本来は手動で行う作業をアドタスカルで自動化・半自動化でき、その手間を効率化。CVが正確に計上されるので広告の自動最適化がかかる！ASPへのタグの設置の依頼も不要！'
  }
},
{
  path: '/yda-cv',
  name: 'yda-cv',
  component: YdaCv,
  props: {},
  meta: {
    title: 'ASP管理画面とYDAのCVの乖離防止｜広告成果連携ツール「アドタスカル」',
    description: 'YDA成果連携ツール「アドタスカル」は、コンバージョンAPI機能によって、ASP管理画面に計上される成果とYDA管理画面上の成果の乖離をなくすツールです。アドタスカルで自動・半自動でコンバージョンの乖離をなくすことができます。正確なCVデータで自動最適化がかかる！ASPへのタグの設置の依頼も不要！'
  }
},
{
  path: '/tiktok-cv',
  name: 'tiktok-cv',
  component: TiktokCv,
  props: {},
  meta: {
    title: 'ASP管理画面とTikTokのCVの乖離防止｜広告成果連携ツール「アドタスカル」',
    description: 'TikTok成果連携ツール「アドタスカル」は、コンバージョンAPI機能によって、ASP管理画面に計上される成果とTikTok管理画面上の成果の乖離をなくすツールです。アドタスカルで自動・半自動でコンバージョンの乖離をなくすことができます。正確なCVデータで自動最適化がかかる！ASPへのタグの設置の依頼も不要！'
  }
},
{
  path: '/contact-form',
  name: 'contact-form',
  component: ContactForm,
  meta: {
    title: 'お問い合わせ｜広告成果連携ツール「アドタスカル」',
    description: ''
  }
},
{
  path: '/contact-form-check',
  name: 'contact-form-check',
  component: ContactFormCheck,
  meta: {
    title: 'お問い合わせ｜広告成果連携ツール「アドタスカル」',
    description: ''
  }
},
{
  path: '/contact-form-complete',
  name: 'contact-form-complete',
  component: ContactFormComplete,
  meta: {
    title: 'お問い合わせ｜広告成果連携ツール「アドタスカル」',
    description: ''
  }
},
{
  path: '/tokuteisho',
  name: 'tokuteisho',
  component: Tokuteisho,
  meta: {
    title: '特定商取引法に基づく表記｜広告成果連携ツール「アドタスカル」',
    description: ''
  }
},
{
  path: '/kiyaku',
  name: 'kiyaku',
  component: Kiyaku,
  meta: {
    title: '利用規約｜広告成果連携ツール「アドタスカル」',
  }
},
{
  path: '/privacy-policy',
  name: 'privacy-policy',
  component: PrivacyPolicy,
  meta: {
    title: 'プライバシーポリシー｜広告成果連携ツール「アドタスカル」',
  }
},
{
  path: '/complete/:temporaryRegisterToken',
  name: 'complete',
  component: Complete,
  props: route => ({
    temporaryRegisterToken: String(route.params.temporaryRegisterToken)
  }),
  meta: {
    title: '会員登録&決済完了｜広告成果連携ツール「アドタスカル」',
  }
},
{
  path: '/complete2/:password',
  name: 'complete2',
  component: OnetimeOfferComplete,
  beforeEnter(to, from, next) {
    if (to.params.password === 'ch3HQAKNvRLHUBBLGKLCaCBxJjbCUTxW') {
      next();
    } else {
      next('/');
    }
  },
  meta: {
    title: '会員登録&決済完了｜広告成果連携ツール「アドタスカル」',
  }
},
{
  path: '/register-error',
  name: 'register-error',
  component: RegisterError,
  meta: {
    title: '登録エラー',
  }
},



// 
{
  path: '/lp-tag',
  name: 'lp-tag',
  component: LpTag,
  meta: {
    title: 'お問い合わせ｜広告成果連携ツール「アドタスカル」',
    description: ''
  }
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  const currentURL = window.location.protocol + "//" + window.location.host;
  console.log(`currentURL: ${currentURL}`);
  setRobots(currentURL);
  setTwitterConfig(to.name);
  store.dispatch("setRt");
  store.dispatch("setVoiceList");
  store.dispatch("setVariousValuesForAllUsers")
    .then((res) => {
      console.log(res);

      // 
      store.dispatch("setMainProductId");

      // ==================== ↓↓ toolDataの保存 ↓↓ ====================
      let toolData = {
        codeKebab: 'tools',
        codeCamel: 'tools',
        mediaName: '',
      };

      if (to.path !== '/') {

        toolData.codeKebab = to.name;
        toolData.codeCamel = convertKebabToCamel(to.name);
        let mediaName = "";
        switch (to.name) {
          case 'google-cv':
            mediaName = "Google広告";
            break;
          case 'facebook-cv':
            mediaName = "Meta広告";
            break;
          case 'yahoo-cv':
            mediaName = "Yahoo!検索広告";
            break;
          case 'tiktok-cv':
            mediaName = "TikTok広告";
            break;
          case 'microsoft-cv':
            mediaName = "Microsoft広告";
            break;
          case 'line-cv':
            mediaName = "LINE広告";
            break;
          case 'yda-cv':
            mediaName = "YDA";
            break;

          default:
            break;
        }
        toolData.mediaName = mediaName;

      } else {
        //no action.
      }
      store.dispatch("setToolData", toolData);
      // ==================== ↑↑ toolDataの保存 ↑↑ ====================


      // 
      let utmSource = '';
      if (from.query.utm_source !== undefined) {
        utmSource = from.query.utm_source;
      } else if (to.query.utm_source !== undefined) {
        utmSource = to.query.utm_source;
      } else {
        // no action.
      }
      store.dispatch('setPrivilegeData', utmSource);


      // ==================== ↓↓ queryパラメータの引き継ぎ ↓↓ ====================

      if ((from.query.utm_source !== undefined && to.query.utm_source === undefined) &&
        (from.query.utm_medium !== undefined && to.query.utm_medium === undefined)
      ) {
        if (to.path === from.path) {
          return;
        }

        // 
        next({
          path: to.path,
          query: {
            utm_source: from.query.utm_source,
            utm_medium: from.query.utm_medium,
            tool_id: from.query.tool_id
          }
        })
      }

      // ==================== ↑↑ queryパラメータの引き継ぎ ↑↑ ====================
      next();

    })
    .catch((err) => {
      console.log(err);
    });
});

export default router