<template>
  <div class="register-error">
    <div class="wrap">
      <h1>登録エラー</h1>
      <div class="contents">
        <div class="description">
          <p>登録中にエラーが発生しました。</p>
          <p>再度、本登録をお試しいただくか、運営に<router-link to="/contact-form">お問い合わせ</router-link>ください。</p>
          <h2>よくあるエラー原因</h2>
          <ul>
            <li>入力した決済情報に誤りがある。</li>
            <li>3Dセキュア認証に失敗した。</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'register-error',
  components: {
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tools", "mainProductId"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 70px 0;

  h1 {
    margin-bottom: 1em;
  }

  .contents {
    display: flex;
    justify-content: center;

    .description {
      width: 800px;

      a {
        text-decoration: underline;
      }

      h2 {
        margin-top: 2em;
      }

      h3 {
        margin-top: 1.5em;
      }
    }
  }
}
</style>