<template>
  <div class="Cost">
    <div class="point_plan">
      <div class="bg_board">
        <p class="txt_size">
          アドタスカルは、ポイントを購入し、そのポイントを利用することで、アドタスカルのすべてのツールを使用することができます。ポイントの有効期限は1年間のため、ポイントの未消化の無駄なくご利用することができます。都度決済による購入と自動更新決済による購入の両方が可能です。
        </p>
        <div class="pc">
          <table>
            <tr>
              <th class="title">プラン名</th>
              <th class="title">料金<span>（税込）</span></th>
              <th class="title">付与ポイント</th>
            </tr>
            <tr>
              <th>{{ productData["planName"] }}プラン</th>
              <td>{{ productData["price"] | localeNum }}<span>円</span></td>
              <td>
                {{ productData["point"] | localeNum }}<span>ポイント</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="sp">
          <table>
            <tr>
              <th colspan="2" class="title">
                {{ productData["planName"] }}プラン
              </th>
            </tr>
            <tr class="border">
              <th>料金<span>（税込）</span></th>
              <td>{{ productData["price"] | localeNum }}<span>円</span></td>
            </tr>
            <tr class="border">
              <th>付与ポイント</th>
              <td>
                {{ productData["point"] | localeNum }}<span>ポイント</span>
              </td>
            </tr>
          </table>
        </div>

        <p class="txt_size">ツールごとに消費されるポイントが決まっています。</p>

        <div class="pc">
          <table v-for="(toolCodeCamel, index) in toolCodeList" :key="index" class="use_point_google">
            <tr>
              <th class="title">{{ tools[toolCodeCamel].name2 }}</th>
            </tr>
            <tr>
              <td v-if="
                toolCodeCamel === 'googleCv' ||
                toolCodeCamel === 'yahooCv' ||
                toolCodeCamel === 'ydaCv' ||
                toolCodeCamel === 'facebookCv' ||
                toolCodeCamel === 'microsoftCv' ||
                toolCodeCamel === 'tiktokCv' ||
                toolCodeCamel === 'squadbeyondCv'
              ">
                <span v-if="
                  toolCodeCamel === 'googleCv' || toolCodeCamel === 'yahooCv' || toolCodeCamel === 'ydaCv' ||
                  toolCodeCamel === 'microsoftCv' || toolCodeCamel === 'tiktokCv' || toolCodeCamel === 'squadbeyondCv'
                " class="to-be-deleted">1日あたり{{
                    tools[toolCodeCamel].point | localeNum
                  }}ポイント</span>
                <span v-else-if="toolCodeCamel === 'facebookCv'" class="to-be-deleted">1日あたり200ポイント</span>
                <p style="color: #f56c6c; font-weight: bold"
                  v-show="toolCodeCamel === 'facebookCv' && isCampaign === true">
                  2022年12月31日まで：1日あたり100ポイント
                </p>
              </td>
              <!-- <td v-else-if="toolCodeCamel === 'tiktokCv'">
                現在、準備中です。
              </td> -->
            </tr>
          </table>
        </div>

        <div class="sp">
          <table v-for="(toolCodeCamel, index) in toolCodeList" :key="index" class="use_point"
            style="margin-bottom: 15px">
            <tr>
              <th class="title">{{ tools[toolCodeCamel].name2 }}</th>
            </tr>
            <tr>
              <td v-if="
                toolCodeCamel === 'googleCv' ||
                toolCodeCamel === 'yahooCv' ||
                toolCodeCamel === 'ydaCv' ||
                toolCodeCamel === 'facebookCv' ||
                toolCodeCamel === 'microsoftCv' ||
                toolCodeCamel === 'tiktokCv' ||
                toolCodeCamel === 'squadbeyondCv'
              ">
                1日あたり{{ tools[toolCodeCamel].point | localeNum }}ポイント
                <p style="color: #f56c6c; font-weight: bold;font-size:13px;"
                  v-show="toolCodeCamel === 'facebookCv' && isCampaign === true">
                  2022年12月31日まで：1日あたり100ポイント
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div id="cost_cart"></div>
        <p class="txt_size">
          ポイントは、購入後1年間有効のため、ポイントがなくなったらポイントを購入(自動更新可能)していただく仕組みとなっています。
        </p>
        <div class="pc">
          <table class="use_point_google">
            <tr>
              <th class="title">カート/計測システム連携ツール</th>
            </tr>
            <tr>
              <td>
                <p style="text-align: left; padding: 10px;">
                  カート/計測システムの利用契約をし、かつ、利用設定がONになっている全ての成果連携ツールでご利用いただくことができます。<br />
                  <br />
                  <span class="bold">■ご利用料金</span><br />
                  <span class="bold">シングルプラン</span><br />
                  月11,000円（税込）<br />
                  1つのカート/計測システムのみ利用したい方向け<br />
                  <br />
                  <span class="bold">プレミアムプラン</span><br />
                  月22,000円（税込）<br />
                  全てのツールが利用できるので２つ以上のカート/計測システムを利用したい方向け<br />
                  <br />
                  ※カート/計測システムのみ銀行振込決済での利用となります。<br />
                  <br />
                  <span class="bold">■契約期間</span><br />
                  3ヶ月毎の契約になっています。<br />
                  利用期間終了の1週間前になりましたら、アドタスカルから利用継続に関するご連絡を差し上げます。<br />
                  解約の場合は、「<router-link to="/payment">ポイント購入・プラン変更</router-link>」画面より、「自動更新設定」を停止してください。<br />
                  <br />
                  <span class="bold">■サポート</span><br />
                  サポートは、個別のチャットワークグループにて行います。<br />
                  カート/計測システム連携以外のアドタスカルに関する内容のサポートも、ご契約中はチャットワークグループにて行わせていただきます。<br />
                  カート/計測システム連携に関してはクライアント様も関わってきますので、必要に応じてZoomなどでのサポートも行います。
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Cost",
  props: {
    productId: String,
  },
  data() {
    return {
      toolCodeList: [],
      isCampaign: false,
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "tools", "apMinValue"]),
    productData() {
      return this.$store.getters.productData(this.productId);
    },
  },
  created() {
    let toolCodeList = [];
    switch (this.tool.codeKebab) {
      case "google-cv":
        toolCodeList = ["googleCv", "yahooCv", "ydaCv", "facebookCv", "microsoftCv", "tiktokCv", "squadbeyondCv"];
        break;
      case "facebook-cv":
        toolCodeList = ["facebookCv", "googleCv", "yahooCv", "ydaCv", "microsoftCv", "tiktokCv", "squadbeyondCv"];
        break;
      case "yahoo-cv":
        toolCodeList = ["yahooCv", "googleCv", "ydaCv", "facebookCv", "microsoftCv", "tiktokCv", "squadbeyondCv"];
        break;
      case "yda-cv":
        toolCodeList = ["ydaCv", "yahooCv", "googleCv", "facebookCv", "microsoftCv", "tiktokCv", "squadbeyondCv"];
        break;
      case "microsoft-cv":
        toolCodeList = ["microsoftCv", "googleCv", "yahooCv", "ydaCv", "facebookCv", "tiktokCv", "squadbeyondCv"];
        break;
      case "tiktok-cv":
        toolCodeList = ["tiktokCv", "googleCv", "yahooCv", "ydaCv", "facebookCv", "microsoftCv", "squadbeyondCv"];
        break;
      default:
        toolCodeList = ["googleCv", "yahooCv", "ydaCv", "facebookCv", "microsoftCv", "tiktokCv", "squadbeyondCv"];
        break;
    }
    this.toolCodeList = toolCodeList;
    console.log(this.toolCodeList);
    this.checkCampaign();
  },
  methods: {
    checkCampaign() {
      let nowDate = new Date();
      let limitDate = new Date('2023-01-01');

      if (nowDate < limitDate) {
        this.isCampaign = true;
      } else {
        this.isCampaign = false;
      }
      console.log(this.isCampaign);
    }
  }
};
</script>

<style lang="scss" scoped>
.Cost {

  // padding-bottom: 80px;
  @media screen and (max-width: $max-width) {
    padding-bottom: 0;
  }
}

.bg_board {
  background-color: #f4f4f4;
  padding: 1px 40px 10px;
  border-radius: 15px;
  margin-bottom: 80px;

  @media screen and (max-width: $max-width) {
    padding: 10px 20px 10px;
    margin-bottom: 50px;
  }

  .txt_size {
    padding: 40px 0;

    @media screen and (max-width: $max-width) {
      padding: 0 0 20px;
    }
  }
}

.point_plan {
  .plan_name {
    font-weight: bold;
    font-size: 24px;

    @media screen and (max-width: $max-width) {
      text-align: center;
    }
  }

  .tax {
    text-align: right;
    margin-bottom: 50px;
  }

  table {
    border-collapse: collapse;
    color: #4d4d4d;

    th {
      width: 550px;
      border: 1px solid #ccc;
      padding: 13px 10px;
      font-size: 18px;
      background-color: #e1ecf6;

      @media screen and (max-width: $max-width) {
        padding: 10px 8px;
        width: 55%;
        font-size: 17px;
      }

      span {
        font-size: 15px;

        @media screen and (max-width: $max-width) {
          font-size: 13px;
        }
      }
    }

    td {
      border: 1px solid #ccc;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      background-color: white;

      @media screen and (max-width: $max-width) {
        padding: 10px;
        font-size: 16px;
      }

      span {
        font-size: 16px;
        margin-left: 5px;
      }
    }

    .title {
      background-color: #1c6db5;
      color: white;
      font-size: 20px;
      font-weight: normal;
      padding: 5px;
      border: 1px solid #1c6db5;
      border-right: 1px solid #ccc;

      @media screen and (max-width: $max-width) {
        font-size: 18px;
      }
    }

    .title02 {
      background-color: #1c6db5;
      color: white;
      font-size: 20px;
      font-weight: normal;
      padding: 5px;
      border: 1px solid #1c6db5;
      border-left: 1px solid #ccc;
    }
  }
}

.b {
  font-weight: bold;
  color: #1c6db5;
}

.bold {
  font-weight: bold;
}

.title {
  margin-top: 100px;

  @media screen and (max-width: $max-width) {
    margin-bottom: 30px;
    margin-top: 80px;
  }
}

.use_point {
  margin-bottom: 50px;

  td {
    font-weight: normal !important;
    font-size: 18px !important;
    padding: 10px 0;

    @media screen and (max-width: $max-width) {
      font-size: 16px !important;
    }
  }
}

.use_point_google {
  margin-bottom: 50px;

  td {
    font-weight: normal !important;
    font-size: 18px !important;
    padding: 10px 0;
    width: 50%;

    @media screen and (max-width: $max-width) {
      font-size: 16px !important;
    }
  }
}

.other {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: $max-width) {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  p {
    border-bottom: 1px solid #ccc;
  }

  ul {
    padding-inline-start: 20px;

    li {
      font-size: 18px;
    }
  }

  .no_limits {
    background-color: white;
    width: 48%;
    padding: 20px 50px 74px;
    box-sizing: border-box;
    border-radius: 10px;

    @media screen and (max-width: $max-width) {
      width: 100%;
      padding: 0 40px;
    }
  }

  .function {
    width: 48%;
    background-color: white;
    padding: 20px 50px;
    box-sizing: border-box;
    border-radius: 10px;

    @media screen and (max-width: $max-width) {
      width: 100%;
      margin-top: 20px;
      padding: 0 40px;
    }
  }
}

.font-style {
  font-size: 18px;
  color: #4d4d4d;
  padding-bottom: 10px;
}
</style>
