import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    rt: 'https://adtasukaru.com',
    queryData: {
      utm_source: '',
      utm_medium: '',
      tool_id: ''
    },
    apiErrorMessage: '',
    tools: {},
    mainProductId: '',
    productList: [],
    bonusPointList: [],
    apMinValue: 0,
    apMaxValue: 0,
    tool: {}, //現在のページのtool情報
    affiliaterData: {
      userId: '0',
      givesPrivilege: false
    },
    privilegeData: {
      privilegeId: '0',
      providerName: '',
      privilegeName: '',
      description: '',
      displayProviderName: '紹介者'
    },
    voiceList: [],
  },
  getters: {
    rt(state) {
      return state.rt;
    },
    queryData(state) {
      return state.queryData;
    },
    apiErrorMessage(state) {
      return state.apiErrorMessage;
    },
    tools(state) {
      return state.tools;
    },
    mainProductId(state) {
      return state.mainProductId
    },
    productList(state) {
      return state.productList
    },
    productData: (state) => (productId) => {
      return state.productList.find(productData => productData.productId === productId);
    },
    bonusPointList(state) {
      return state.bonusPointList
    },
    apMinValue(state) {
      return state.apMinValue
    },
    apMaxValue(state) {
      return state.apMaxValue
    },
    tool(state) {
      let toolData = state.tool;
      if (
        toolData.codeCamel === 'googleCv' ||
        toolData.codeCamel === 'facebookCv' ||
        toolData.codeCamel === 'yahooCv'
      ) {
        toolData.id = String(state.tools[state.tool.codeCamel].id);
      } else {
        toolData.id = '0';
      }
      return toolData;
    },
    affiliaterData(state) {
      return state.affiliaterData
    },
    privilegeData(state) {
      return state.privilegeData
    },
    // getVoiceList: (state) => (voiceId) => {
    //   return state.voiceList.find(voice => voice.id === id);
    // },
    VoiceList(state) {
      return state.voiceList
    },
    voiceList: (state) => (voiceIdList) => {
      let voiceList = [];
      voiceIdList.forEach(voiceId => {
        let voiceData = {};
        voiceData = state.voiceList.find(voiceData => voiceData.voiceId === voiceId)
        voiceList.push(voiceData);
      });
      return voiceList;
    },
  },
  mutations: {
    setRt(state, payload) {
      state.rt = payload;
    },
    setAffiliaterData(state, payload) {
      state.affiliaterData = payload.affiliaterData;
    },
    setPrivilegeData(state, payload) {
      state.privilegeData = payload.privilegeData;
    },
    setQueryData(state, payload) {
      state.queryData = payload;
    },
    setApiErrorMessage(state, payload) {
      state.apiErrorMessage = payload;
    },
    setTools(state, array) {
      state.tools = array;
    },
    setMainProductId(state) {
      let productId = '25';
      let dateNow = new Date();
      let limitDate = new Date(2022, 7, 1, 0, 0, 0); // 2022-08-01 00:00:00
      if (limitDate.getTime() <= dateNow.getTime()) {
        productId = '31';
      } else {
        // no action.
      }
      state.mainProductId = productId;
    },
    setProductList(state, payload) {
      state.productList = payload;
    },
    setBonusPointList(state, payload) {
      state.bonusPointList = payload;
    },
    setApValue(state, payload) {
      state.apMinValue = payload.min;
      state.apMaxValue = payload.max;
    },
    setToolData(state, object) {
      state.tool = object;
    },
    setVoiceList(state, payload) {
      state.voiceList = payload;
      console.log('===== koko =====');
      console.log(payload);
      console.log(state.voiceList);
    },
  },
  actions: {
    setRt({
      commit
    }) {
      let rt;
      if (process.env.NODE_ENV === 'development') {
        if (location.host === 'adtasukaru-wp.com') {
          rt = "https://adtasukaru-wp.com";
        } else {
          rt = "http://adtasukaru.localhost";
        }
      } else {
        rt = "https://adtasukaru.com";
      }
      commit('setRt', rt);
    },
    setPrivilegeData({
      getters,
      commit
    }, utmSource) {
      // 分けたほうがいいかも
      let affiliaterData = {
        userId: '0',
        givesPrivilege: false
      };

      // 
      let privilegeData = {
        privilegeId: '0',
        providerName: '',
        privilegeName: '',
        description: '',
        displayProviderName: false
      }
      axios
        .get(`${getters.rt}/api/lp/affiliate/get-privilege-data.php?utm_source=${utmSource}`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            affiliaterData = response.data.affiliaterData;
            privilegeData = response.data.privilegeData;
          } else {
            // no action.
          }

          // 分けたほうがいいかも
          commit('setAffiliaterData', {
            affiliaterData: affiliaterData
          });
          commit('setPrivilegeData', {
            privilegeData: privilegeData
          });
          return;
        })
        .catch(error => {
          console.log(error);
          return;
        });
    },
    setQueryData({
      commit,
      dispatch
    }, payload) {
      commit('setQueryData', payload);

      //queryDataを追加するついでに特典情報もチェック→追加
      dispatch('setPrivilegeData', payload);
    },
    setVariousValuesForAllUsers({
      getters,
      commit,
      dispatch
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${getters.rt}/api/getVariousValue.php`)
          .then(res => {
            console.log(res);
            commit('setBonusPointList', res.data.bonusPointList);
            commit('setApiErrorMessage', res.data.apiErrorMessage);
            commit('setTools', res.data.tools);
            commit('setApValue', {
              min: res.data.apMinValue,
              max: res.data.apMaxValue
            });
            dispatch('setProductList');
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      })
    },
    setToolData({
      commit
    }, payload) {
      commit('setToolData', payload);
    },
    setMainProductId({
      commit
    }) {
      commit('setMainProductId');
    },
    setProductList({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rt}/api/getProductsTable.php?pageType=lp`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            commit('setProductList', res.data.productList);
            return;
          } else {
            alert(getters.apiErrorMessage + "（setProductList）");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setVoiceList({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rt}/api/lp/getVoice.php`)
        .then((response) => {
          console.log(response);
          commit('setVoiceList', response.data.voiceList);
          // console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  plugins: [createPersistedState()],
  modules: {}
})